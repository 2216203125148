<template>
    <v-btn v-if="to"
           class="cwu-btn btn-h-48 pl-10 pr-10"
           color="#646464"
           elevation="0"
           outlined
           rounded
           :to="to">
        <slot/>
    </v-btn>
    <v-btn
        v-else
        class="cwu-btn btn-h-48 pl-10 pr-10"
        color="#646464"
        elevation="0"
        outlined
        rounded
        @click="onClick">
        <slot/>
    </v-btn>
</template>

<script>
    export default {
        name: "btn-gray",
        props: {
            to: {
                type: [Object, String],
                default: ""
            }
        },
        methods: {
            onClick (e) {

                this.$emit("click", e);
            }
        }
    };
</script>
