<template>
    <div>
        <div class="header d-flex">
            <div class="content">
                <div class="container d-flex justify-space-between w-full">
                    <div class="d-flex flex-column">
                        <div class="header_tag">Blog</div>
                        <h1 class="header_title">Learn more about our use cases and what makes UNCO great for you</h1>
                    </div>
                    <div class="header_img-container">
                        <img class="header_img" src="@/assets/blog/head-img.png" alt="main image"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div v-if="latestArticle" class="container d-flex flex-column">
                <div class="latest-releases">
                    <h2 class="latest-releases_title">Latest Releases</h2>
                    <div class="d-flex justify-space-between flex-column flex-md-row">
                        <div class="latest-releases_img-container">
                            <router-link :to="`/blog/${latestArticle.slug}`">
                                <img class="latest-releases_img" :src="latestArticle.list_image.url" :alt="latestArticle.list_image.original_name"/>
                            </router-link>
                        </div>
                        <div class="latest-releases_content-container d-flex flex-column justify-center">
                            <div class="d-flex align-center">
                                <article-badge :type="latestArticle.type"/>
                                <div class="latest-releases_date">{{ $moment(latestArticle.post_date).format('MMMM DD, YYYY') }}</div>
                            </div>
                            <h3 class="latest-releases_content-title">{{ latestArticle.title }}</h3>
                            <p class="latest-releases_content-text" v-html="latestArticle.short_description"></p>
                            <btn-gray :to="`/blog/${latestArticle.slug}`" class="latest-releases_btn">Learn More</btn-gray>
                        </div>
                    </div>
                </div>
                <separator/>
                <div class="list pt-11 pb-16" ref="article-list">
                    <div class="d-flex justify-space-between flex-wrap align-center mb-7">
                        <div class="search-input mb-4">
                            <v-text-field
                                v-model="search"
                                label="Search"
                                hide-details
                                single-line
                                prepend-inner-icon="mdi-magnify"></v-text-field>
                        </div>
                        <div class="d-flex flex-wrap">
                            <button class="category-btn mr-2 mb-4" :class="[activeCategory === 'all' ? 'active' : '']" @click="activeCategory = 'all'">All</button>
                            <button v-for="category in blogCategories" :key="category.id" class="category-btn mr-2 mb-4" :class="[activeCategory === category.id ? 'active' : '']"
                                    @click="activeCategory = category.id">
                                {{ category.title }}
                            </button>
                        </div>
                    </div>
                    <div class="list-articles">
                        <article-card v-for="article in articlesList" :key="article.id" :data="article"/>
                    </div>
                    <div v-if="pagination.count !== pagination.total" class="d-flex justify-center mt-12">
                        <v-btn
                            class="btn-green"
                            outlined
                            rounded
                            @click="loadMore">
                            Load More
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BtnGray from "@/components/ui/btn/BtnGray";
    import Separator from "@/components/blog/Separator"
    import ArticleCard from "@/components/blog/ArticleCard"
    import ArticleBadge from "@/components/blog/ArticleBadge"
    import axios from "axios";
    export default {
        name: "page-blog",
        components: {
            BtnGray,
            Separator,
            ArticleCard,
            ArticleBadge
        },
        data() {
            return {
                search: this.$route.query.search,
                activeCategory: +this.$route.query.category || "all",
                blogCategories: [],
                articlesList: [],
                pagination: {
                    per_page: 4,
                    count: 0,
                    total: 0,
                    current_page: 1
                },
                debounce: null,
                latestArticle: null
            }
        },
        watch: {
            "pagination.per_page": {
                async handler(newVal) {
                    await this.getArticles()
                }
            },
            activeCategory: {
                async handler(newVal) {
                    const currentQuery = this.$route.query;
                    const y = document.querySelector(".list").getBoundingClientRect().top + window.scrollY - 60;
                    const mergedQuery = { ...currentQuery, category: newVal === "all" ? "": newVal };
                    this.$router.push({ query: mergedQuery, params: { savePosition: true } })
                    this.$nextTick(() => {
                        window.scroll({
                            top: y,
                            behavior: "auto"
                        });
                    })
                    
                    await this.getArticles()
                }
            },
            search: {
                async handler(newVal) {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(async () => {
                        const currentQuery = this.$route.query;
                        const y = document.querySelector(".list").getBoundingClientRect().top + window.scrollY - 60;
                        const mergedQuery = { ...currentQuery, search: newVal };
                        this.$router.push({ query: mergedQuery, params: { savePosition: true } })
                        this.$nextTick(() => {
                            window.scroll({
                                top: y,
                                behavior: "auto"
                            });
                        })
                        await this.getArticles()
                    }, 300)
                    
                }
            }
        },
        async mounted() {
            await Promise.all([this.getBlogCategories(), this.getArticles(), this.getLatestArticles()])
        },
        methods: {
            loadMore() {
                this.pagination.per_page = this.pagination.per_page + 4
            },
            async getArticles() {
                try {
                    const params = {
                        per_page: this.pagination.per_page,
                        page: this.pagination.current_page,
                        search: this.search
                        // blog_category_id: this.activeCategory === "all" ? "" : this.activeCategory
                    }
                    params[`order_by[id]`] = "DESC"
                    if(this.activeCategory !== "all") {
                        params.blog_category_id = this.activeCategory
                    }
                    const { data } = await axios.get(process.env.VUE_APP_BASE_API_URL + `/blogs`, { params });
                    this.articlesList = data.data
                    this.pagination.total = data.meta.pagination.total
                    this.pagination.count = data.meta.pagination.count
                } catch (err) {
                    console.log(err)
                }
                
            },
            async getBlogCategories() {
                try {
                    const params = {
                        "except_blank": 1
                    }
                    const { data } = await axios.get(process.env.VUE_APP_BASE_API_URL + `/blog-categories`, { params });
                    this.blogCategories = data.data
                } catch (err) {
                    console.log(err)
                }
            },
            async getLatestArticles() {
                try {
                    const params = {
                        per_page: 1,
                        page: 1
                    }
                    params[`order_by[id]`] = "DESC"

                    const { data } = await axios.get(process.env.VUE_APP_BASE_API_URL + `/blogs`, { params });
                    if(data.data.length) {
                        this.latestArticle = data.data[0]
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.content {
    padding: 0 50px;
    width: 100%;
}
.container {
    max-width: 1280px;
    padding: 0;
    height: 100%;
}
.header {
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(90deg, #EFEFEF 76%, #424242 60%);
    height: 500px;
    @media screen and (max-width: 1060px) {
        background-image: linear-gradient(90deg, #EFEFEF 100%, #424242 60%);
    }
    @media screen and (min-width: 3000px) {
        background-image: linear-gradient(90deg, #EFEFEF 69%, #424242 60%);
    }
    &_img-container {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            display: block;
            right: 0;
            width: 500px;
            height: 100%;
            transform: translate(50%, 0);
            background: #424242;
        }
        @media screen and (max-width: 1060px) {
            display: none;
        }
    }
    &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: fit-content;
        right: 0;
    }
    &_tag {
        width: fit-content;
        background: #3F7359;
        border-radius: 20px;
        font-weight: 450;
        font-size: 16px;

        color: #FFFFFF;
        padding: 0px 16px;
        margin-top: 50px;
    }
    &_title {
        font-weight: 500;
        font-size: 50px;
        line-height: 100%;
        max-width: 450px;
        margin-top: 30px;
        @media screen and (min-width: 600px) {
            font-size: 56px;
        }
    }
}
.latest-releases {
    padding: 54px 0 70px;
    &_title {
        font-weight: 450;
        font-size: 48px;
        margin-bottom: 52px;
    }
    &_img-container {
        width: 100%;
        @media screen and (min-width: 960px) {
            width: 60%;
        }
    }
    &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &_content-container {
        width: 100%;
        margin-top: 20px;
        @media screen and (min-width: 960px) {
            margin-top: 0;
            width: 40%;
            padding-left: 62px;
        }
    }
    &_date {
        font-weight: 500;
        font-size: 14px;
        color: #6B6B6B;     
    }
    &_content-title {
        font-weight: 500;
        font-size: 48px;
        color: #464646;
        line-height: 1;
        margin-top: 16px;
    }
    &_content-text {
        font-size: 18px;
        line-height: 23px;
        margin-top: 26px;
    }
    &_btn {
        width: fit-content;
        padding-left: 80px !important;
        padding-right: 80px !important;
    }
}

.list {
    // padding: 44px 0 92px;
}
.search-input {
    width: 240px;
    ::v-deep {
        .primary--text {
        color: #464646 !important;
        caret-color: #464646 !important;
    }
    input, label, .theme--light {
        color: #464646 !important;
    }
    .v-input__slot {
        &::before {
            border-color: #464646;
        }
    }
    }
   
}
.category-btn {
    height: 34px;
    border: 1px solid #464646;
    border-radius: 50px;
    padding: 0px 35px;
    font-size: 14px;
    line-height: 18px;
    transition: all 0.2s;
    &:hover {
        opacity: 0.8;
    }
    &.active {
        background: #D9FBDD;
    }
}
.list-articles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 74px;
    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.btn-green {
    padding: 10px 70px !important;
    background: #9FF4A9;
    border: 1px solid #464646;
    border-radius: 50px;
}
</style>